import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { blue } from '@material-ui/core/colors';
import { useEffect, useState } from 'react';

const TotalMilage = (props) => {
  const [totalMilage, setTotalMileage] = useState(0);
  const { details } = props;
  useEffect(() => {
    // console.log('TotalMilage details ', details);
    if (details && details.length >= 0) {
      let r = 0;
      // console.log(details);
      details.forEach(element => {
        r += element.realMileage;
      });
      setTotalMileage(r.toFixed(2));
    }
  }, [details]);

  return (
    <Card
      sx={{ height: '100%', boxShadow: 12 }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between'}}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL MILEAGE
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {totalMilage} Miles
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: blue[600],
                height: 56,
                width: 56
              }}
            >
              <DirectionsRunIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};

export default TotalMilage;
