import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { updateRunnerPassword } from 'src/api';


const AccountPassword = (props) => {
  const { profile } = props;
  const [values, setValues] = useState();

  const [saveMessageDisplay, setSaveMessageDisplay] = useState("none");
  const [saveMessage, setSaveMessage] = useState();
  const [saveMessageCode, setSaveMessageCode] = useState();

  //saveButtonDisabled
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const handleChange = (event) => {
    //console.log(values);
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    setValues((values) => ({
      ...values,
      id: profile ? profile.id : ''
    }));
  }, [profile]);

  const passRegularExpression = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/

  const setTheMessage = (type, msg) => {
    setSaveMessageDisplay("");
    setSaveMessageCode(type);
    setSaveMessage(msg);
  }

  const passHint = 'Password must be 6~16 digits or letters.';

  const handleUpdatePassword = async () => {
    if (!values.currentPass || !passRegularExpression.test(values.currentPass)) {
      setTheMessage('error', 'Current ' + passHint);
      return;
    }
    if (!values.newPass || !passRegularExpression.test(values.newPass)) {
      setTheMessage('error', 'New ' + passHint);
      return;
    }
    if (!values.confirmPass || !passRegularExpression.test(values.confirmPass)) {
      setTheMessage('error', 'Confirm ' + passHint);
      return;
    }
    if (!(values.confirmPass === values.newPass)) {
      setTheMessage('error', 'Confirm password not same as the new password.');
      return;
    }

    setSaveMessageDisplay("none");
    setSaveButtonDisabled(true);
    console.log('updateRunnerPassword: ', values);
    const saveResult = await updateRunnerPassword(values);
    // console.log(saveResult);
    setSaveMessageDisplay("");
    setSaveButtonDisabled(false);
    if (saveResult.code === 1) {
      setTheMessage("success", "Password updated.");
    } else {
      setTheMessage("error", saveResult.msg);
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          // subheader="The information can be edited"
          title="Change Password"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="Current Password"
                name="currentPass"
                type="password"
                onChange={handleChange}
                value={values ? values.currentPass : ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="New Password"
                name="newPass"
                type="password"
                onChange={handleChange}
                value={values ? values.newPass : ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="Confirm Password"
                name="confirmPass"
                type="password"
                onChange={handleChange}
                value={values ? values.confirmPass : ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Alert severity={saveMessageCode} sx={{ display: saveMessageDisplay }}>
            {saveMessage}
          </Alert>
          <Button
            color="primary"
            variant="contained"
            disabled={saveButtonDisabled}
            onClick={handleUpdatePassword}
          >
            Update Password
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountPassword;
