import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { updateRunnerProfile } from 'src/api';

const AccountProfileDetails = (props) => {
  //console.log("props.profile", props.profile);
  const { profile, setReloadProfile } = props;
  const [values, setValues] = useState();

  const [saveMessageDisplay, setSaveMessageDisplay] = useState("none");
  const [saveMessage, setSaveMessage] = useState();
  const [saveMessageCode, setSaveMessageCode] = useState();
  //saveButtonDisabled
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    // console.log('load profile to values ...', profile);
    if (profile) {
      delete profile.avatar;
      delete profile.password;
      setValues({
        ...profile
      });
    }
  }, [profile]);

  const handleUpdateProfile = async () => {
    // console.log('To handleUpdateProfile: ', values);
    setSaveMessageDisplay("none");
    setSaveButtonDisabled(true);
    const saveResult = await updateRunnerProfile(values);
    // console.log(saveResult);
    setSaveMessageDisplay("");
    setSaveButtonDisabled(false);
    if (saveResult.code === 1) {
      setSaveMessageCode("success");
      setSaveMessage(saveResult.data);
      setReloadProfile(pre => pre + 1);
    } else {
      setSaveMessageCode("error");
      setSaveMessage(saveResult.msg);
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          // subheader="The information can be edited"
          title="Runner Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="First Name"
                name="firstName"
                onChange={handleChange}
                value={values ? values.firstName : ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                value={values ? values.lastName : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                value={values ? values.email : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="中文名"
                name="cnName"
                onChange={handleChange}
                value={values ? values.cnName : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-gender-native-simple">Gender</InputLabel>
                <Select
                  native
                  value={values ? values.gender : ''}
                  onChange={handleChange}
                  label="Gender"
                  inputProps={{
                    name: 'gender',
                    id: 'outlined-gender-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={'男'}>男</option>
                  <option value={'女'}>女</option>
                </Select>
              </FormControl>
            </Grid>
{/* 
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-agegroup-native-simple">Age Group</InputLabel>
                <Select
                  native
                  value={values ? values.ageGroup : ''}
                  onChange={handleChange}
                  label="Age Group"
                  inputProps={{
                    name: 'ageGroup',
                    id: 'outlined-agegroup-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={'20以下'}>20以下</option>
                  <option value={'20-30'}>20-30</option>
                  <option value={'30-40'}>30-40</option>
                  <option value={'40-50'}>40-50</option>
                  <option value={'50-60'}>50-60</option>
                  <option value={'60以上'}>60以上</option>
                </Select>
              </FormControl>
            </Grid> */}

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Birth Year"
                name="birthYear"
                onChange={handleChange}
                value={values ? values.birthYear : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                value={values ? values.phone : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={handleChange}
                value={values ? values.city : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              {/* <TextField
                fullWidth
                label="Select State"
                name="state"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values ? values.state : ''}
                variant="outlined"
              >
                {states.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField> */}
              <TextField
                fullWidth
                label="State"
                name="state"
                onChange={handleChange}
                value={values ? values.state : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                value={values ? values.country : ''}
                variant="outlined"
              />
            </Grid>

            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-alumni-native-simple">Alumni</InputLabel>
                <Select
                  native
                  value={values ? values.alumni : ''}
                  onChange={handleChange}
                  label="Alumni"
                  inputProps={{
                    name: 'alumni',
                    id: 'outlined-alumni-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={'华盛顿特区校友会'}>华盛顿特区校友会</option>
                  <option value={'北京大学校友会 （限大华府地区）'}>北京大学校友会 （限大华府地区）</option>
                  <option value={'清华大学校友会 （限大华府地区）'}>清华大学校友会 （限大华府地区）</option>
                  <option value={'新英格兰地区校友会'}>新英格兰地区校友会</option>
                  <option value={'大纽约地区校友会'}>大纽约地区校友会</option>
                  <option value={'俄亥俄州辛辛那提地区'}>俄亥俄州辛辛那提地区</option>
                  <option value={'宾州费城地区'}>宾州费城地区</option>
                  <option value={'芝加哥地区校友会'}>芝加哥地区校友会</option>
                  <option value={'密西根地区校友会'}>密西根地区校友会</option>
                  <option value={'明尼苏达地区'}>明尼苏达地区</option>
                  <option value={'密苏里州圣路易斯地区'}>密苏里州圣路易斯地区</option>
                  <option value={'印第安纳地区a'}>印第安纳地区</option>
                  <option value={'休斯顿地区校友会'}>休斯顿地区校友会</option>
                  <option value={'亚利桑那凤凰城地区'}>亚利桑那凤凰城地区</option>
                  <option value={'北加州地区校友会'}>北加州地区校友会</option>
                  <option value={'南加州地区校友会'}>南加州地区校友会</option>
                  <option value={'多伦多地区校友会'}>多伦多地区校友会</option>
                  <option value={'温哥华地区校友会'}>温哥华地区校友会</option>
                  <option value={'TBD'}>TBD</option>
                </Select>
              </FormControl>
            </Grid> */}

            {/* 华盛顿特区校友会
            北京大学校友会 （限大华府地区）
            清华大学校友会 （限大华府地区）
            新英格兰地区校友会
            大纽约地区校友会
            俄亥俄州辛辛那提地区
            宾州费城地区
            芝加哥地区校友会
            密西根地区校友会
            明尼苏达地区
            密苏里州圣路易斯地区
            印第安纳地区
            休斯顿地区校友会
            亚利桑那凤凰城地区
            北加州地区校友会
            南加州地区校友会
            多伦多地区校友会
            温哥华地区校友会
            TBD */}

            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-runningGroup-native-simple">组别</InputLabel>
                <Select
                  native
                  value={values ? values.runningGroup : ''}
                  onChange={handleChange}
                  label="组别"
                  inputProps={{
                    name: 'runningGroup',
                    id: 'outlined-runningGroup-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={'成年男子组'}>成年男子组</option>
                  <option value={'成年女子组'}>成年女子组</option>
                  <option value={'少年组'}>少年组</option>
                  <option value={'儿童组'}>儿童组</option>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-university-native-simple">毕业学校</InputLabel>
                <Select
                  native
                  value={values ? values.university : ''}
                  onChange={handleChange}
                  label="毕业学校"
                  inputProps={{
                    name: 'university',
                    id: 'outlined-university-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={'西安交通大学'}>西安交通大学</option>
                  <option value={'上海交通大学'}>上海交通大学</option>
                  <option value={'西南交通大学'}>西南交通大学</option>
                  <option value={'上海大学'}>上海大学</option>
                  <option value={'阳明交通大学'}>阳明交通大学</option>
                  <option value={'北京大学'}>北京大学</option>
                  <option value={'清华大学（北京）'}>清华大学（北京）</option>
                  <option value={'北京交通大学'}>北京交通大学</option>
                </Select>
              </FormControl>
            </Grid> */}
            {/* 
            上海交通大学
            上海大学
            北京大学
            清华大学（北京）
            西南交通大学
            西安交通大学
            阳明交通大学
            北京交通大学 */}

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="毕业学校"
                name="university"
                onChange={handleChange}
                value={values ? values.university : ''}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="加入年份"
                name="yearJoin"
                onChange={handleChange}
                value={values ? values.yearJoin : ''}
                variant="outlined"
              />
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Alert severity={saveMessageCode} sx={{ display: saveMessageDisplay }}>
            {saveMessage}
          </Alert>
          <Button
            color="primary"
            variant="contained"
            disabled={saveButtonDisabled}
            onClick={handleUpdateProfile}
          >
            Save Profile
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
