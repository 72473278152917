import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { createRunningDetails, uploadImg } from 'src/api';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { IMGAE_REQUEST_PREFIX } from 'src/utils/appConstants';
import moment from 'moment';
import { useNavigate } from 'react-router';


const AccountProfileDetails = (props) => {
  const navigate = useNavigate();
  const { profile, eventId } = props;
  const [values, setValues] = useState({
    eventId: eventId,
    mileage: '',
    duration: '',
    kcal: '',
    runDate: moment(new Date()).format("yyyy-MM-DD"),
    note: ''
  });

  const [photoUrl, setPhotoUrl] = useState('');

  const [saveMessageDisplay, setSaveMessageDisplay] = useState("none");
  const [saveMessage, setSaveMessage] = useState();
  const [saveMessageCode, setSaveMessageCode] = useState();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  useEffect(() => {
    setValues((values) => ({
      ...values,
      mileageUnit: 'Mile',
      runnerId: profile ? profile.id : ''
    }));
  }, [profile]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDateChange = (datePick) => {
    setValues({
      ...values,
      runDate: moment(datePick._d).format("yyyy-MM-DD")
    });
  };

  const handlePhotoChange = async (event) => {
    // upload the photo
    const r = await uploadImg(event.target.files[0]);
    if (r.code === 1) {
      setPhotoUrl(IMGAE_REQUEST_PREFIX + r.data);
      setValues({
        ...values,
        imgUrl: r.data
      });
      setSaveMessageDisplay('');
      setSaveMessageCode("success");
      setSaveMessage('Photo uploaded.');
    } else {
      setSaveMessageDisplay('');
      setSaveMessageCode("error");
      setSaveMessage(r.msg);
    }
  }

  const handleUpdate = async () => {
    if (!values.mileage || isNaN(values.mileage) || values.mileage < 0) {
      setSaveMessageDisplay('');
      setSaveMessageCode("error");
      setSaveMessage("Mileage should be a positive number.");
      return;
    }
    if (!values.duration || isNaN(values.duration) || values.duration < 0) {
      setSaveMessageDisplay('');
      setSaveMessageCode("error");
      setSaveMessage("Duration should be a positive number.");
      return;
    }

    setSaveMessageDisplay("none");
    setSaveButtonDisabled(true);
    console.log('createRunningDetails: ', values);
    const saveResult = await createRunningDetails(values);
    setSaveMessageDisplay("");
    setSaveButtonDisabled(false);
    if (saveResult.code === 1) {
      setSaveMessageCode("success");
      setSaveMessage(saveResult.data);
    } else {
      setSaveMessageCode("error");
      setSaveMessage(saveResult.msg);
    }
    navigate(`/app/dashboard?eid=${eventId}`, { replace: true });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card sx={{ boxShadow: 12 }}>
        <CardHeader
          title="Running Submit"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Grid container justifyContent="space-between">
                <Grid
                  md={8.9}
                  xs={8.9}
                >
                  <TextField
                    fullWidth
                    label="Mileage"
                    name="mileage"
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  md={3}
                  xs={3}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-unit-native-simple">Unit</InputLabel>
                    <Select
                      native
                      onChange={handleChange}
                      label="Unit"
                      value={values ? values.mileageUnit : 'Mile'}
                      inputProps={{
                        name: 'mileageUnit',
                        id: 'outlined-unit-native-simple',
                      }}
                    >
                      <option value={'Mile'}>Mile</option>
                      <option value={'Km'}>Km</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Grid container justifyContent="space-between">
                <Grid
                  md={8.9}
                  xs={8.9}
                >
                  <TextField
                    fullWidth
                    label="Duration"
                    name="duration"
                    onChange={handleChange}
                    value={values ? values.duration : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  md={3}
                  xs={3}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-timeunit-native-simple">Time Unit</InputLabel>
                    <Select
                      native
                      onChange={handleChange}
                      label="Time Unit"
                      inputProps={{
                        name: 'timeUnit',
                        id: 'outlined-timeunit-native-simple',
                      }}
                    >
                      <option value={'Minute'}>Minute</option>
                      {/* <option value={'Hour'}>Hour</option> */}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Grid container justifyContent="space-between">
                <Grid
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Steps"
                    name="steps"
                    onChange={handleChange}
                    value={values ? values.steps : ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <KeyboardDatePicker
                InputProps={{ readOnly: true }}
                clearable
                fullWidth
                name="runDate"
                value={values ? values.runDate : new Date()}
                onChange={date => handleDateChange(date)}
                format="MM/DD/yyyy"
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Comment"
                name="note"
                onChange={handleChange}
                value={values ? values.note : ''}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            {photoUrl && <Grid
              item
              md={12}
              xs={12}
            >
              <img src={photoUrl} width="100%" height="auto" alt='the pic you upload' />
            </Grid>}
            <Grid
              item
              md={12}
              xs={12}
            >
              <Button variant="text" fullWidth component="label" color="primary">
                Upload photo
                <input hidden accept="image/*" type="file" onChange={handlePhotoChange} />
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Alert severity={saveMessageCode} sx={{ display: saveMessageDisplay }}>
            {saveMessage}
          </Alert>
          <Button
            color="primary"
            variant="contained"
            disabled={saveButtonDisabled}
            onClick={handleUpdate}
          >
            Submit
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
