import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  AppBar, Tabs, Tab
} from '@material-ui/core';
import TotalMilage from 'src/components/dashboard/TotalMilage';
import TotalRunDuration from 'src/components/dashboard/TotalRunDuration';
import MyRunningDetails from 'src/components/dashboard/MyRunningDetails';
import localStorageUtils from 'src/utils/localStorageUtils';
import { Navigate } from 'react-router';
import { useEffect, useState } from 'react';
import { requestProfile, requestRunningDetails } from 'src/api';
import { Link, useSearchParams } from 'react-router-dom';
import TotalComments from 'src/components/dashboard/TotalComments';
import SwipeableViews from 'react-swipeable-views';
import Honors from 'src/components/dashboard/Honors';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const currentUser = localStorageUtils.getCurrentUser();
  const [events, setEvents] = useState([]);
  const [runningDetailList, setRunningDetailList] = useState();
  const [reloadDetails, setReloadDetails] = useState(0);
  const [urlParams] = useSearchParams();
  const eventId = urlParams.get("eid");

  //tabs
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const loadEvents = async (runnerId) => {
    const result = await requestProfile(runnerId);
    setEvents(result.data.runningEvents);
  };

  useEffect(() => {
    const initRunningDetails = async () => {
      if (currentUser.id) {
        loadEvents(currentUser.id);
        const r = await requestRunningDetails(currentUser.id);
        const fullRunningList = r.data;
        if (eventId) {
          setRunningDetailList(fullRunningList.filter((running) => running.eventId === eventId));
        } else {
          setRunningDetailList(fullRunningList);
        }
      }
    };
    initRunningDetails();
  }, [reloadDetails, currentUser.id, eventId]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Meta Run</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              {
                events.map((item) => {
                  if (item.id === eventId) {
                    return <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h2" >{item.title} {item.tagNumber ? '(#' + item.tagNumber + ')' : ''}</Typography>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        href={`/app/running-detail?eid=${eventId}`}
                      >
                        Running Submit
                      </Button>
                    </Box>
                  }
                })
              }

            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={12}
            >
              <TotalMilage details={runningDetailList} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={12}
            >
              <TotalRunDuration details={runningDetailList} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={4}
              xl={4}
              xs={12}
            >
              <TotalComments details={runningDetailList} />
            </Grid>
            {/* <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <TotalKcal details={runningDetailList} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <TotalRanking details={runningDetailList} />
            </Grid> */}
            {/* <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <Sales />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <TrafficByDevice sx={{ height: '100%' }} />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <LatestProducts sx={{ height: '100%' }} />
            </Grid> */}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Data" {...a11yProps(0)} />
                  <Tab label="Honors" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                  <MyRunningDetails details={runningDetailList} reloadDetails={setReloadDetails} events={events} eventId={eventId} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Honors eventid={eventId} runner={currentUser} />
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
