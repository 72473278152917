import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2'
    },
    secondary:{
      contrastText: '#ffffff',
      main: '#c456d2'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    error: {
      main: '#d25664',
    },
    success: {
      main: '#64D256',
    },
    info: {
      contrastText: '#ffffff',
      main: '#56a2d2'      
    },
  },
  shadows,
  typography
});

export default theme;
