import { useEffect, useState } from 'react';
import { Avatar, Box, TextField, Button, Card, CardContent, CardHeader, Checkbox, Divider, Grid, Select, MenuItem, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createRunningDetails, uploadImg, requestRunningDetails } from 'src/api';
import { GoogleMap, LoadScript, Polyline } from '@react-google-maps/api';
import { Navigate } from 'react-router';
import moment from 'moment';
import localStorageUtils from 'src/utils/localStorageUtils';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';     // map, photo
import polyline from '@mapbox/polyline';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: '20px',
  },
  avatar: {
    width: 200,
    height: 200,
  },
});

const StravaCallback = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const eventId = urlParams.get("eid");

  const [userDetails, setUserDetails] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [expandedActivities, setExpandedActivities] = useState([]);
  const [loadedMaps, setLoadedMaps] = useState({});

  const ActivityMap = ({ activity }) => {
    return (
      <>
        <Button onClick={() => setLoadedMaps(prev => ({ ...prev, [activity.id]: true }))}>
          Load Map
        </Button>
        {loadedMaps[activity.id] && activity.map.summary_polyline && (
          <LoadScript googleMapsApiKey="AIzaSyBMAEqhvuwPdj_9TO2mB1l_2LguumnPidU">
            {(() => {
              const coordinates = polyline.decode(activity.map.summary_polyline);
              const firstLatLng = coordinates.length > 0 ? { lat: coordinates[0][0], lng: coordinates[0][1] } : { lat: 0, lng: 0 };
              return (
                <GoogleMap
                  mapContainerStyle={{ height: "400px", width: "100%" }}
                  zoom={12}
                  center={firstLatLng}
                >
                  <Polyline
                    path={coordinates.map((coordinate) => ({ lat: coordinate[0], lng: coordinate[1] }))}
                  />
                </GoogleMap>
              );
            })()}
          </LoadScript>
        )}
      </>
    );
  };

  // Add state for distance unit
  const [distanceUnit, setDistanceUnit] = useState('miles');

  // Handle distance unit change
  const handleDistanceUnitChange = (event) => {
    setDistanceUnit(event.target.value);
  };

  // Add state for time format
  const [timeFormat, setTimeFormat] = useState('hours');

  // Handle time format change
  const handleTimeFormatChange = (event) => {
    setTimeFormat(event.target.value);
  };

  const handleImageChange = (e, activityId) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);  // Create URL for the image
    setActivityData(prevData =>
      prevData.map(activity =>
        activity.id === activityId
          ? { ...activity, photoFile: file, photoUrl: url }
          : activity
      )
    );
  }

  const [comment, setComment] = useState({});

  const handleChange = (event, id) => {
    setComment({
      ...comment,
      [id]: event.target.value,
    });
  };

  const handleRowClick = (id) => {
    if (expandedActivities.includes(id)) {
      setExpandedActivities(expandedActivities.filter(activityId => activityId !== id));
    } else {
      setExpandedActivities([...expandedActivities, id]);
    }
  };

  const convertSecondsToHoursMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours} hours ${minutes} minutes`;
  }

  const [savedActivities, setSavedActivities] = useState([]);

  // Fetch saved activities for the current user when the component mounts
  useEffect(() => {
    const fetchSavedActivities = async () => {
      const currentUser = localStorageUtils.getCurrentUser();

      if (!currentUser || !currentUser.id) {
        return <Navigate replace to="/login" />;
      }

      const response = await requestRunningDetails(currentUser.id);
      if (response.code === 1) {
        setSavedActivities(response.data.map(activity => activity.stravaId));
      } else {
        console.error('Failed to fetch saved activities');
      }
    };

    fetchSavedActivities();
  }, []);

  useEffect(() => {
    console.log('selectedActivities', selectedActivities);
    console.log('distanceUnit', distanceUnit);
  }, [selectedActivities]);

  useEffect(() => {
    const accessToken = localStorageUtils.getAccessToken();
    if (accessToken && !localStorageUtils.isTokenExpired(accessToken)) {
      setUserDetails(localStorageUtils.getAthlete());
      setAccessToken(accessToken.accessToken);
    } else {
      const query = new URLSearchParams(location.search);
      const code = query.get('code');
      const returnedState = query.get('state');
      const error = query.get('error');

      if (error) {
        console.error('Error from Strava:', error);
        navigate('/error');
        return;
      }

      const sessionState = sessionStorage.getItem('stravaState');
      if (returnedState !== sessionState) {
        console.error('State mismatch error. Potential CSRF attack.');
        navigate('/error');
        return;
      }

      // Create the body of POST request
      const clientId = '109559';
      const clientSecret = '8a24ca7c802eae483a3d3d3bfe02cafa6d9c3459';
      let details = {
        client_id: clientId,
        client_secret: clientSecret,
        code: code,
        grant_type: "authorization_code"
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      // Make the POST request
      fetch('https://www.strava.com/oauth/token', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
      })

        .then(response => response.json())
        .then(data => {
          setUserDetails(data.athlete);
          setAccessToken(data.access_token);
          console.log(data);
          localStorageUtils.setAccessToken(data.access_token, data.expires_in);
          localStorageUtils.setAthlete(data.athlete);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    // Fetch user's activities
    fetch('https://www.strava.com/api/v3/athlete/activities', {
      mode: 'cors',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })

      .then(response => response.json())
      .then(data => {
        const updatedData = data.map(activity => ({ ...activity }));
        setActivityData(updatedData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [accessToken]);

  const handleSelectAllActivities = (event) => {
    const notDisabledActivities = activityData
      .filter(activity => !savedActivities.includes(activity.id.toString()))
      .map((activity) => activity.id);

    if (notDisabledActivities.every(activity => selectedActivities.includes(activity))) {
      setSelectedActivities(selectedActivities.filter(activity => !notDisabledActivities.includes(activity)));
      setExpandedActivities([]);
    } else {
      setSelectedActivities(Array.from(new Set([...selectedActivities, ...notDisabledActivities])));
      setExpandedActivities(notDisabledActivities);
    }
  };

  const handleSelectOneActivity = (event, id) => {
    const selectedIndex = selectedActivities.indexOf(id);
    let newSelectedActivities = [];

    if (selectedIndex === -1) {
      newSelectedActivities = newSelectedActivities.concat(selectedActivities, id);
    } else if (selectedIndex === 0) {
      newSelectedActivities = newSelectedActivities.concat(selectedActivities.slice(1));
    } else if (selectedIndex === selectedActivities.length - 1) {
      newSelectedActivities = newSelectedActivities.concat(selectedActivities.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedActivities = newSelectedActivities.concat(
        selectedActivities.slice(0, selectedIndex),
        selectedActivities.slice(selectedIndex + 1)
      );
    }

    setSelectedActivities(newSelectedActivities);
  };

  const handleSubmit = async () => {
    const currentUser = localStorageUtils.getCurrentUser();
    let tasks = activityData.filter(act => selectedActivities.includes(act.id)).map(async (activity) => {

      // Upload the photo and get the url
      let imgUrl = null;
      if (activity.photoFile) {
        const r = await uploadImg(activity.photoFile);
        if (r.code !== 1) {
          // Handle upload error...
          return;
        }
        imgUrl = r.data;
      }

      // Prepare the values object for this activity
      const values = {
        eventId: eventId,
        mileage: distanceUnit === 'miles' ? (activity.distance / 1609.34).toFixed(2) : (activity.distance / 1000).toFixed(2),
        duration: (activity.moving_time / 60).toFixed(2),
        kcal: activity.calories / 1000,
        runDate: moment(activity.start_date).format("yyyy-MM-DD"),
        runnerId: currentUser.id,
        stravaId: activity.id,
        mileageUnit: distanceUnit === 'miles' ? 'Mile' : 'Km',
        note: comment[activity.id],
        imgUrl: imgUrl,
        stravaPloyline: activity.map.summary_polyline   // coordinate
      };

      return createRunningDetails(values);
    });

    try {
      await Promise.all(tasks);
    } catch (error) {
      // Handle errors...
      console.log(error);
    }
    navigate(`/app/dashboard?eid=${eventId}`, { replace: true });
  };

  if (!userDetails || !activityData) {
    return <div>Loading...</div>;
  }

  return (
    <Box p={3}>
      <Card className={classes.root}>
        <CardContent >
          <Grid container spacing={6} lg={12} md={12} xs={12}>
            <Grid item >
              <Avatar src={userDetails.profile} className={classes.avatar} sx={{ width: 100, height: 100, marginBottom: 3 }} />
            </Grid>
            <Grid item>
              <Typography variant="h5">{userDetails.username}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item>
              <Typography variant="body2" sx={{ marginBottom: 1 }}>Distance Unit</Typography>
              <Select value={distanceUnit} onChange={handleDistanceUnitChange} size="small">
                {/* Disable the meters for now */}
                {/* <MenuItem value="meters">Meters</MenuItem> */}
                <MenuItem value="kilometers">Kilometers</MenuItem>
                <MenuItem value="miles">Miles</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ marginBottom: 1 }}>Time Format</Typography>
              <Select value={timeFormat} onChange={handleTimeFormatChange} size="small">
                <MenuItem value="seconds">Seconds</MenuItem>
                <MenuItem value="hours">Hours:Minutes</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
        <CardHeader title="Activities" />
        <Divider />
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <TableContainer>
            <Table sx={{ minWidth: 300 }}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedActivities.length === activityData.length}
                      color="primary"
                      indeterminate={selectedActivities.length > 0 && selectedActivities.length < activityData.length}
                      onChange={handleSelectAllActivities}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Distance</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Strava ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityData.map((activity, index) => {
                  const isOpen = expandedActivities.includes(activity.id);
                  const isSaved = savedActivities.includes(activity.id.toString());
                  return (
                    <>
                      <TableRow
                        hover
                        key={activity.id}
                        selected={selectedActivities.indexOf(activity.id) !== -1}
                        onClick={() => handleRowClick(activity.id)}
                      >
                        {/* <ThemeProvider theme={theme}> */}
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedActivities.indexOf(activity.id) !== -1}
                            onChange={(event) => handleSelectOneActivity(event, activity.id)}
                            value="true"
                            disabled={isSaved}
                            icon={isSaved ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                          />
                        </TableCell>
                        {/* </ThemeProvider> */}
                        <TableCell>
                          {activity.name}
                        </TableCell>
                        <TableCell>
                          {activity.start_date_local}
                        </TableCell>
                        <TableCell>
                          {distanceUnit === 'meters'
                            ? `${activity.distance} m`
                            : distanceUnit === 'kilometers'
                              ? `${(activity.distance / 1000).toFixed(2)} km`
                              : `${(activity.distance / 1609.34).toFixed(2)} mi` // Convert to miles
                          }
                        </TableCell>
                        <TableCell>
                          {timeFormat === 'seconds'
                            ? `${activity.moving_time} s`
                            : convertSecondsToHoursMinutes(activity.moving_time)
                          }
                        </TableCell>
                        <TableCell>{activity.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        {isOpen && (
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Grid container spacing={3}>
                              <Grid item md={6} xs={12}>
                                <Box margin={1}>
                                  <TextField
                                    fullWidth
                                    label="Comment"
                                    name="comment"
                                    onChange={(e) => handleChange(e, activity.id)}
                                    value={comment[activity.id] || ''}
                                    variant="outlined"
                                    multiline
                                  />
                                  {activity.photoUrl &&
                                    <img src={activity.photoUrl} width="100%" height="auto" alt='the selected pic' />
                                  }
                                  <Button variant="text" fullWidth component="label" color="primary">
                                    Upload photo
                                    <input hidden accept="image/*" type="file" onChange={(e) => handleImageChange(e, activity.id)} />
                                  </Button>
                                </Box>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <ActivityMap activity={activity} />
                              </Grid>
                            </Grid>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            <Box m={3} sx={{ display: 'flex', justifyContent: 'center' }} >
              <Button color="primary" variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
              <Button color="secondary">
                <Link to={`/app/running-detail?eid=${eventId}`}>Cancel</Link>
              </Button>
            </Box>
          </TableContainer>
        </Box>
      </Card>
    </Box>
  );
};

export default StravaCallback;
