import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import { green } from '@material-ui/core/colors';
import { useEffect, useState } from 'react';

const TotalRunDuration = (props) => {
  const [total, setTotal] = useState(0);
  const { details } = props;
  useEffect(() => {
    if (details && details.length >= 0) {
      let r = 0;
      details.forEach(element => {
        r += element.duration;
      });
      setTotal(r);
    }
  }, [details]);

  return (
    <Card
      sx={{ height: '100%', boxShadow: 12 }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TOTAL TIME
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {total} Minutes
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <TimerIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};

export default TotalRunDuration;
