import { Helmet } from 'react-helmet';
import {
  Box,
  Container
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const AboutTheDisease = () => {
  const dkw = useSelector((state) => state.dkw);

  return (
    <>
      <Helmet>
        <title>Top Hospitals | LINKMedicine</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <h1>{dkw.toUpperCase()}</h1>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AboutTheDisease;
