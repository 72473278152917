const Logo = (props) => (
  <img
    alt="Logo"
    src="/static/LM.jpg"
    width="auto"
    height="50"
    {...props}
  />
);

export default Logo;
