import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid, Container, Divider, Button, Box } from '@material-ui/core';
import localStorageUtils from 'src/utils/localStorageUtils';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { requestProfile } from 'src/api';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IMGAE_REQUEST_PREFIX } from 'src/utils/appConstants';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  registerButton: active => ({
    backgroundColor: active ? 'green' : 'red',
    color: 'white',
  }),
});

const EventCard = ({ event }) => {
  const [active, setActive] = useState(event.active);
  const classes = useStyles(active);

  // Format start and end date
  const startDate = new Date(event.startDate);
  const endDate = new Date(event.endDate);
  const today = new Date();

  // Format dates to mm/dd/yyyy
  const startDateFormat = startDate.toLocaleDateString();
  const endDateFormat = endDate.toLocaleDateString();

  const handleClick = () => {
    setActive(prev => !prev);
  };

  return (
    <Link to={`/app/dashboard?eid=${event.id}`}>
      <Card className={classes.card} sx={{ paddingRight: 1, boxShadow: 12 }}>
        <CardContent>      
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {event.title}
          </Typography>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'row' }} p={3}>
            <Box mr={3}>
              <img src={IMGAE_REQUEST_PREFIX + event.imageUrl} width="100px" height="100px" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
              <Typography variant="h5" component="h2">
                Date: {`${startDateFormat} - ${endDateFormat}`}
              </Typography>
              <Typography variant="h5" component="h2">
                Host by: {event.host1}
              </Typography>
              <Typography variant="h5" component="h2">
                Active: {active ? 'Yes' : 'No'}  {/*description, picture?*/}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        
        {/* {(today >= startDate && today <= endDate) && 
          <Button className={classes.registerButton} onClick={handleClick}>
            {active ? 'Unregister' : 'Register'}
          </Button>
        } */}
        <ArrowForwardIosIcon />
      </Card>
    </Link>
  );
};

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const currentUser = localStorageUtils.getCurrentUser();

  const loadEvents = async (runnerId) => {
    const result = await requestProfile(runnerId);
    setEvents(result.data.runningEvents);
  };

  useEffect(() => {
    loadEvents(currentUser.id);
  }, [currentUser.id]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" sx={{ marginTop: 3 }}>Events</Typography>
      <Grid container>
        {events.map((event, index) => (
          <Grid item xs={12} key={index}>
            <EventCard event={event} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EventsPage;
