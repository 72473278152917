import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import RunningDetails from 'src/components/running/RunningDetails';
import localStorageUtils from 'src/utils/localStorageUtils';
import { Navigate } from 'react-router';
import { requestProfile } from 'src/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

const RunningSubmit = () => {
  const [profile, setProfile] = useState();
  const currentUser = localStorageUtils.getCurrentUser();
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const eventId = urlParams.get("eid");

  const loadProfile = async (runnerId) => {
    const result = await requestProfile(runnerId);
    // console.log('loadProfile: ', result);
    setProfile(result.data);
  };

  useEffect(() => {
    loadProfile(currentUser.id);
  }, [currentUser.id]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  const handleAuthStart = () => {
    const accessToken = localStorageUtils.getAccessToken();
    if (accessToken && !localStorageUtils.isTokenExpired(accessToken)) {
      navigate(`/strava-callback?eid=${eventId}`);
    } else {
      // Generate a random string for state
      const state = Math.random().toString(36).substring(7);
      sessionStorage.setItem('stravaState', state);  // Save the state parameter in session

      const clientId = '109559';
      const redirectUri = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/strava-callback?eid=${eventId}`);  // this should change when ready to deploy
      const scope = encodeURIComponent('read,activity:read');

      console.log(redirectUri);

      const stravaAuthUrl = `http://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=force&scope=${scope}&state=${state}`;

      window.location.href = stravaAuthUrl;
    }
  };

  return (
    <>
      <Helmet>
        <title>Running Detail | Meta Run</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="sm">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              {
                profile && profile.runningEvents && profile.runningEvents.map((item) => {
                  if (item.id === eventId) {
                    return <><Typography variant="h2" >{item.title}</Typography>
                      <RunningDetails profile={profile} eventId={eventId} /></>
                  }
                })
              }

            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  minHeight: '100%',
                  py: 3,
                  px: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button variant="contained" color="secondary" onClick={handleAuthStart}>
                  Submit with Strava
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
};

export default RunningSubmit;
