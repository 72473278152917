const CURRENT_USER = 'CURRENT_USER';
const localStorageUtils = {
  setCurrentUser(user) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(user || {}));
  },

  removeCurrentUser() {
    localStorage.removeItem(CURRENT_USER);
  },

  getCurrentUser() {
    return JSON.parse(localStorage.getItem(CURRENT_USER) || '{}');
  },

  getAthlete() {
    const token = localStorage.getItem('stravaAthlete');
    return token ? JSON.parse(token) : null;
  },

  removeAthlete() {
    localStorage.removeItem('stravaAthlete');
  },
  
  setAthlete(athlete) {
    const token = JSON.stringify(athlete);
    localStorage.setItem('stravaAthlete', token);
  },
  
  getAccessToken() {
    const token = localStorage.getItem('stravaAccessToken');
    return token ? JSON.parse(token) : null;
  },
  
  setAccessToken(accessToken, expiresIn) {
    const expireTime = new Date().getTime() + expiresIn * 1000; // convert expiresIn from seconds to milliseconds
    const token = JSON.stringify({
      accessToken,
      expireTime
    });
    localStorage.setItem('stravaAccessToken', token);
  },

  removeAccessToken() {
    localStorage.removeItem('stravaAccessToken');
  },
  
  isTokenExpired(token) {
    if (!token) {
      return true;
    }
    return token.expireTime <= new Date().getTime(); // If the current time is past the token's expiry time, the token is expired
  }
  
};


export default localStorageUtils;