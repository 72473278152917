import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { toJpeg } from 'html-to-image';
import { useEffect, useState } from 'react';
import { requestRunnerHonors } from 'src/api';

const Honors = (props) => {

  const { runner, eventid } = props;
  console.log(runner);

  const [honors, setHonors] = useState([]);

  const saveTheSVG = () => {
    var svgData = document.getElementById("hornor001").outerHTML;
    var svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(svgBlob);
    link.download = "hornor.svg";
    link.click();
  }

  const saveTheHonor = () => {
    toJpeg(document.getElementById('hornor001'), { canvasWidth: 800, canvasHeight: 600 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'honor.jpeg';
        link.href = dataUrl;
        link.click();
      });
  }

  const loadHonors = async (runnerId) => {
    const result = await requestRunnerHonors(runnerId);
    if (result.code === 1 && result.data.length > 0) {
      console.log('honors', result.data);
      const currentEventHonors = result.data.filter((item) => (item.eventId == eventid));
      console.log('currentEventHonors', currentEventHonors);
      // for now suppose to be only one honor
      setHonors(currentEventHonors);
    }
  };

  useEffect(() => {
    loadHonors(runner.id);
  }, []);

  return (
    <Card
      {...props}
    >
      <CardContent sx={{ p: 3 }}>
        {honors.length > 0 ? <Grid
          container
          sx={{ justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}
        >
          <Box maxWidth="800px" width="100%" id='hornorBox'>
            <svg viewBox="0 0 240 180" xmlns="http://www.w3.org/2000/svg" id='hornor001'>
              <image width="100%" href="https://app.linkmedicine.com/common/download?name=honor12.jpeg" />
              <text x="50%" y="100" text-anchor="middle" style={{ font: "6px fangsong" }}>{honors[0].honor}</text>
              <text x="50%" y="115" text-anchor="middle" style={{ font: "8px fangsong bold" }}>{runner.cnName}</text>
              <text x="50%" y="130" text-anchor="middle" style={{ font: "8px fangsong bold" }}>{runner.firstName + ' ' + runner.lastName + ' #' + honors[0].tagNumber}</text>
              
              <text x="30" y="142" style={{ font: "4px fangsong" }}>{'国家: ' + runner.country}</text>
              <text x="30" y="147" style={{ font: "4px fangsong" }}>{'团队: ' + honors[0].category}</text>

              <text x="105" y="142" style={{ font: "4px fangsong" }}>{'总时长: ' + honors[0].eventTotalTime}分钟</text>
              <text x="105" y="147" style={{ font: "4px fangsong" }}>{'总里程: ' + (honors[0].eventTotalMileage * 1.61).toFixed(2)}公里</text>
              <text x="105" y="152" style={{ font: "4px fangsong" }}>{'总人数: ' + honors[0].eventTotalRunners}</text>

              <text x="170" y="142" style={{ font: "4px fangsong" }}>{'个人分钟数: ' + honors[0].totalRunningTime}分钟</text>
              <text x="170" y="147" style={{ font: "4px fangsong" }}>{'个人公里数: ' + (honors[0].totalRunningMileage * 1.61).toFixed(2)}公里</text>
              <text x="170" y="152" style={{ font: "4px fangsong" }}>{'发表感想数: ' + honors[0].totalRunningNotes}</text>
            </svg>
          </Box>
          <Button onClick={saveTheHonor}>Download</Button>
        </Grid> : <>No Honor</>}
      </CardContent>
    </Card>
  )
};

export default Honors;
