import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Card,
  Grid,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  makeStyles,
  TableRow
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useState } from 'react';
import { IMGAE_REQUEST_PREFIX } from 'src/utils/appConstants';
import { Link } from 'react-router-dom';
import { updateRunningDetails } from 'src/api';

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    color: 'blue',
    fontWeight: 'bold',
  },
  eventCard: {
    display: 'inline-block',
    border: props => props.selected ? '2px solid #4682B4' : 'none',
    borderRadius: '15px',  // This makes the corners round.
    margin: '10px',
    padding: '10px',
    // cursor: 'pointer',
  },
});


const EventCard = ({ event, selectEvent, selectedEvent }) => {
  const isSelected = selectedEvent && selectedEvent.id === event.id;
  const classes = useStyles({ selected: isSelected, color: event.colorCode });

  const handleClick = () => {
    selectEvent(isSelected ? null : event);
  };

  const formattedDates = `${new Date(event.startDate).toLocaleDateString()} - ${new Date(event.endDate).toLocaleDateString()}`;

  return (
    <Card
      // onClick={handleClick}
      style={{ backgroundColor: event.colorCode, width: "100%" }}
      className={classes.eventCard}
    >
      <Typography className={classes.title}>{event.title}</Typography>
      <Divider />
      <Typography>Date: {formattedDates}</Typography>
      {/* <Typography variant="body2" color="textSecondary"> {event.eventUrl}</Typography> */}
      <a href={`${event.eventUrl}`} target='_blank'>{event.eventUrl}</a>
      <Typography variant="body2">{event.notes}</Typography>
      <Typography variant="body2">{event.contactEmail}</Typography>
      <Typography variant="body2">{event.host1}</Typography>
      <Typography variant="body2">{event.host2}</Typography>
      <Typography variant="body2">{event.host3}</Typography>
      <Typography variant="body2">{event.active ? 'Active' : 'Inactive'}</Typography>
    </Card>
  );
};

const ActivityCard = ({ activity, events, selectEvent, handleClickOpenDeleteDialog, assignEventToActivity }) => {
  const event = events.find(e => e.id === activity.eventId);

  // handle row click
  const handleRowClick = () => {
    assignEventToActivity(activity, selectEvent.id);
  }

  return (
    <TableRow
      hover
      key={activity.id}
      style={{ backgroundColor: event ? event.colorCode : 'white' }}
    // onClick={handleRowClick}
    >
      <TableCell>
        {activity.runDate}
      </TableCell>
      <TableCell>
        {activity.mileage} ({activity.mileageUnit}s)
      </TableCell>
      <TableCell>
        {activity.steps}
      </TableCell>
      <TableCell>
        {activity.duration} (Minutes)
      </TableCell>
      <TableCell>
        {activity.imgUrl ? <img alt="running" src={IMGAE_REQUEST_PREFIX + activity.imgUrl} width="100px" height="auto" /> : ''}
      </TableCell>
      <TableCell>
        {activity.note}
      </TableCell>
      <TableCell>
        <Button variant="text" color="primary" onClick={() => { handleClickOpenDeleteDialog(activity) }}>
          Delete
        </Button>
      </TableCell>

      {/* <TableCell>
        {activity.stravaId}
      </TableCell> */}
    </TableRow>
  );
};

const MyRunningDetails = (props) => {
  const { details, reloadDetails, events, eventId } = props;
  const [open, setOpen] = useState(false);
  const [runningToDelete, setRunningToDelete] = useState();

  const handleClickOpenDeleteDialog = (running) => {
    setRunningToDelete({ ...running });
    setOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setRunningToDelete({});
    setOpen(false);
  };

  const [selectedEvent, setSelectedEvent] = useState(null);

  const selectEvent = (event) => {
    setSelectedEvent(event);
  };

  const assignEventToActivity = async (values) => {
    if (selectedEvent) {
      const runningDetail = {
        ...values,
        eventId: selectedEvent.id,
      };
      const r = await updateRunningDetails(runningDetail);
      if (r.code === 1) {
        reloadDetails(prevState => prevState + 1);
      }
    }
  };

  const handleDeleteRunning = async () => {
    runningToDelete.deleted = 1;
    const r = await updateRunningDetails(runningToDelete);
    if (r.code === 1) {
      reloadDetails(prevState => prevState + 1);
    }
    setOpen(false);
  }

  return (
    <>
      <Box>
        <Accordion defaultExpanded={true} sx={{ display: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography style={{ fontWeight: 500, fontSize: 18 }}>Events</Typography> {/* Here is your updated Typography with inline styles */}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} width="100%"> {/* Wrap EventCards with Grid container */}
              {events.map(event => (
                event.id === eventId &&
                <Grid item width="100%"> {/* Each EventCard is now a Grid item */}
                  <EventCard key={event.id} event={event} selectEvent={selectEvent} selectedEvent={selectedEvent} />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box >
      <Card>
        <CardHeader title="My Runnings" />
        <Divider />
        <PerfectScrollbar>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Date
                  </TableCell>
                  <TableCell>
                    Mileage
                  </TableCell>
                  <TableCell>
                    Steps
                  </TableCell>
                  <TableCell>
                    Duration
                  </TableCell>
                  <TableCell>
                    Photo
                  </TableCell>
                  <TableCell>
                    Comment
                  </TableCell>
                  <TableCell>
                    Action
                  </TableCell>
                  {/* <TableCell>
                    Strava ID
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.map((activity) => (
                  <ActivityCard key={activity.id} activity={activity} events={events} selectEvent={selectEvent} handleClickOpenDeleteDialog={handleClickOpenDeleteDialog} assignEventToActivity={assignEventToActivity} />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
            color: '#c456d2'
          }}
        >
          <Button
            color="secondary"
            endIcon={<ArrowRightIcon />}
            size="large"
            variant="text"
          >
            <Link to={`/app/running-detail?eid=${eventId}`}>Running Submit</Link>
          </Button>
        </Box>
        <Dialog
          open={open}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Do you want to delete this running?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {runningToDelete?.mileage} {runningToDelete?.mileageUnit}s
              <br />
              {runningToDelete?.duration} minutes
              <br />
              {runningToDelete?.runDate}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteRunning} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};

export default MyRunningDetails;
