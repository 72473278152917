import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import App from './App';
import store from './redux/store';

ReactDOM.render((
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
