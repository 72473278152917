import ajax from './ajax';

export const requestTopHospitals = (dkw) => ajax.get('getTopHospitals.do', { params: { dkw } });

export const requestLogin = (email, password) => ajax.post('/runner/login', { email, password });

export const requestProfile = (id) => ajax.get('/runner/' + id);

export const requestRunningDetails = (runnerId) => ajax.get('/running/detail/all/' + runnerId);

export const updateRunnerProfile = (runner) => ajax.put('/runner', runner);

export const updateRunnerPassword = (passwords) => ajax.post('/runner/updatepass', passwords);

export const createRunningDetails = (runningDetails) => ajax.post('/running/detail', runningDetails);

export const updateRunningDetails = (runningDetails) => ajax.put('/running/detail', runningDetails);

export const deleteRunningDetails = (id) => ajax.delete('/running/detail', { params: { id } });

export const downloadImg = (name) => ajax.get('/common/download', { params: { name } });

export const requestRunnerHonors = (runnerId) => ajax.get('/runner/' + runnerId + '/honors');

export const uploadImg = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return ajax.post('/common/upload', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
};
