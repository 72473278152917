import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import AccountProfile from 'src/components/account/AccountProfile';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';
import localStorageUtils from 'src/utils/localStorageUtils';
import { Navigate } from 'react-router';
import { requestProfile } from 'src/api';
import AccountPassword from 'src/components/account/AccountPassword';

const Account = () => {

  const [profile, setProfile] = useState();
  const [reloadProfile, setReloadProfile] = useState(0);
  const currentUser = localStorageUtils.getCurrentUser();

  const loadProfile = async (runnerId) => {
    const result = await requestProfile(runnerId);
    // console.log('loadProfile: ', result);
    setProfile(result.data);
    localStorageUtils.setCurrentUser(result.data);
  };

  useEffect(() => {
    // console.log('loadProfile: ');
    loadProfile(currentUser.id);
  }, [currentUser.id, reloadProfile]);

  if (!currentUser || !currentUser.id) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Helmet>
        <title>Account | Meta Run</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}            
          >
           
            <Grid item lg={8} md={6} xs={12}>
              <Grid sx={{ boxShadow: 12 }}><AccountProfileDetails profile={profile} setReloadProfile={setReloadProfile} /></Grid>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Grid sx={{ boxShadow: 12 }}><AccountProfile profile={profile} setReloadProfile={setReloadProfile} /></Grid>
              <Grid marginTop={3} sx={{ boxShadow: 12 }}><AccountPassword profile={profile} /></Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
};

export default Account;
