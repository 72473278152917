import localStorageUtils from 'src/utils/localStorageUtils';
import { Navigate } from 'react-router';

const Logout = () => {
  localStorageUtils.removeCurrentUser();
  localStorageUtils.removeAthlete();
  localStorageUtils.removeAccessToken();
  return <Navigate replace to="/login" />;
};

export default Logout;
